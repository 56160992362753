import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ClearIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import * as uuid from 'uuid';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";

import pgnParser from 'pgn-parser';

import { pgnToPdfUrl, pgnToLaTeX } from './pdfoutput';
import { emitEvent, eventTypes } from '../../events';

import settings from '../../settings';
import { logButtonClick } from '../../utils';


const app = initializeApp(settings.firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage();

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    maxWidth: 1200,
    margin: '0 auto',
  },
  header: {
    margin: '0 auto',
    maxWidth: 600,
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  stepper: {
    backgroundColor: 'inherit',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 340,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  generator: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    width: '100%',
  },
  clearButton: {
    textTransform: 'none',
    color: '#999',
  },
  textOptions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  textOption: {
    maxWidth: 150,
    marginRight: theme.spacing(1),
  },
  purchaseButton: {
    width: 250,
    marginRight: theme.spacing(1),
    '& div fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
    },
    '& input': {
      color: '#fff !important',
    },
    '& label': {
      color: '#999 !important',
    },
  },
  purchaseEmail: {
    marginRight: theme.spacing(1),
    '& div fieldset': {
      borderColor: 'white',
    },
  },
  numberOptions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  numberOption: {
    maxWidth: 300,
    marginRight: theme.spacing(1),
  },
  boolOptions: {
    margin: theme.spacing(2),
  },
  boolOption: {
    marginTop: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  generateButton: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
    minWidth: 300,
  },
  generateProgress: {
    color: 'white',
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(1),
  },
  shares: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  twitterShare: {
  },
  sidebar: {
    flex: 1,
    padding: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  sidebarSubheader: {
    marginTop: theme.spacing(2),
    fontSize: 14,
  },
  sidebarVideoContainer: {
    marginTop: theme.spacing(2),
  },
  sidebarButton: {
    marginTop: theme.spacing(4),
    minWidth: 300,
  },
});

const steps = [
  {
    label: 'Paste your PGN in the text area below, or use the example PGN provided there',
  },
  {
    label: 'Select options you want',
  },
  {
    label: 'Click "Generate PDF"',
  },
];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseEmail: '',
      purchaseStep: 0,
      generating: false,
      textOptions: {
        leftHeader: '',
        rightHeader: '',
        leftFooter: '',
      },
      numberOptions: {
        enumeratePagesFrom: 1,
        enumerateDiagramsFrom: 1,
      },
      boolOptions: {
        includeGameInfo: true,
        includeSolutionSpace: false,
        includeSolutionPages: true,
      },
      pgnImport: `[Event "World Championship"]
[Date "2016.11.30"]
[Round "13.4"]
[FEN "2R5/4bppk/1p1p4/5R1P/4PQ2/5P2/r4q1P/7K w - - 5 50"]
[White "Magnus Carlsen"]
[Black "Sergey Karjakin"]
[Result "1-0"]

1. Qh6+ {Black resigned here} Kxh6 {Black must take} (1... gxh6 2. Rxf7#) 2. Rh8# 1-0

[Event "New Delhi"]
[White "B Adhiban"]
[Black "Upadhyaya Anwesh"]
[Date "2005"]
[FEN "r4rk1/3n1p2/1q4pp/1p3N1P/1P1QP3/p4R2/P5P1/5RK1 w - - 1 0"]

1. Ne7+ Kh7 2. Rxf7+ *

[Event "Mate in 2"]
[Site "?"]
[White "?"]
[Black "?"]
[Result "1-0"]
[FEN "8/1R5p/5Rqk/4B1pP/p5P1/5p2/5P1K/8 w - - 0 1"]

1. Re6 a3 2. Bg7# 1-0

[Event "Mate in 2"]
[FEN "5rk1/p1p1r1p1/bp2p1Qp/8/3PBq2/2N5/P7/K2n3R b - - 0 1"]

1... Qc1+ 2. Bb1 (2. Nb1 Qb2#) Qb2# 0-1
      `,
    };
  }

  changeTextOption = (field, value) => {
    let newTextOptions = { ...this.state.textOptions };
    newTextOptions[field] = value;
    this.setState({
      textOptions: newTextOptions,
    });
  }

  changeNumberOption = (field, value) => {
    let newNumberOptions = { ...this.state.numberOptions };
    newNumberOptions[field] = parseInt(value);
    this.setState({
      numberOptions: newNumberOptions,
    });
  }

  changeBoolOption = (field, value) => {
    let newBoolOptions = { ...this.state.boolOptions };
    newBoolOptions[field] = value;
    this.setState({
      boolOptions: newBoolOptions,
    });
  }

  clearPgn = () => {
    this.setState({ pgnImport: '' });
  }

  createTexFile = (pgn) => {
    const { textOptions, numberOptions, boolOptions } = this.state;

    const filename = uuid.v4();
    const storageRef = ref(storage, `tmp/${filename}.tex`);
    const value = pgnToLaTeX(pgn, { ...textOptions, ...numberOptions, ...boolOptions });
    this.setState({
      generating: true,
    }, () => {
      uploadString(storageRef, value).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          this.setState({
            generating: false,
          }, () => {
            const apiUrl = `https://texlive2020.latexonline.cc/compile?url=${encodeURIComponent(downloadURL)}`;
            window.open(apiUrl, '_blank').focus();
          });
        });
      });
    })
  }

  setPurchaseStep = (step) => {
    this.setState({ purchaseStep: step });
  }

  render() {
    const { classes } = this.props;
    const { textOptions, numberOptions, boolOptions } = this.state;
    const { leftHeader, rightHeader, leftFooter } = textOptions;
    const { enumeratePagesFrom, enumerateDiagramsFrom } = numberOptions;
    const { includeGameInfo, includeSolutionSpace, includeSolutionPages } = boolOptions;
    const { pgnImport } = this.state;
    const { generating } = this.state;
    const { purchaseStep } = this.state;
    const { purchaseEmail } = this.state;

    let pgn = false;
    try {
      pgn = pgnParser.parse(pgnImport);
    } catch (error) {
      if (error.name === 'SyntaxError') {
      }
    }

    return (
      <Container className={classes.container}>
        <Dialog 
          open={purchaseStep === 1}
          onClose={() => { this.setPurchaseStep(0)}}
        >
          <DialogTitle>Become Member to Unlock It</DialogTitle>
          <DialogContent>
            Become a Member to remove this text or replace it with any text you like!
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => { this.setPurchaseStep(0) }}
            >Cancel</Button>
            <Button 
              onClick={() => { this.setPurchaseStep(2); emitEvent(eventTypes.purchaseButtonClick) }}
              variant="contained"
              color="secondary"
              style={{ fontWeight: 'bold' }}
            >Purchase Membership</Button>
          </DialogActions>
        </Dialog>
        <Dialog 
          open={purchaseStep === 2}
          onClose={() => { this.setPurchaseStep(0)}}
        >
          <DialogTitle>This functionality is coming soon!</DialogTitle>
          <DialogContent>
            <Typography>
              Leave your email and get notified when it's available
            </Typography>
          </DialogContent>
          <DialogContent>
            <form 
              action="https://chessvision.us20.list-manage.com/subscribe/post?u=8aabf528ed8ec6e2afc360e74&amp;id=7a1c3a01ca&amp;f_id=006f5ee6f0" 
              method="post" 
              id="mc-embedded-subscribe-form" 
              name="mc-embedded-subscribe-form" 
              target="_blank" 
            >
              <TextField 
                type="email"
                name="EMAIL"
                size="small"
                variant="outlined"
                required
                id="mce-EMAIL"
                label="Email"
                fullWidth
                value={purchaseEmail}
                onChange={(e) => { this.setState({ purchaseEmail: e.target.value })}}
                className={classes.purchaseEmail}
              />
              <input type="hidden" name="b_8aabf528ed8ec6e2afc360e74_7a1c3a01ca" value="" />
            </form>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => { this.setPurchaseStep(0) }}
            >Close</Button>
            <Button 
              id="mc-embedded-subscribe" 
              type="submit" 
              form="mc-embedded-subscribe-form" 
              variant="contained" 
              color="secondary"
              style={{ fontWeight: "bold" }}
              disabled={!purchaseEmail}
            >Get notified</Button>
          </DialogActions>
        </Dialog>
        <div className={classes.header}>
          <Typography variant="h4" style={{ fontWeight: 400 }}>
            Convert chess <strong style={{fontWeight: 800}}>PGN</strong> to a beautiful <strong style={{fontWeight: 800}}>PDF</strong>
          </Typography>
        </div>
        <Stepper orientation="vertical" className={classes.stepper}>
          {steps.map(({ label }) => (
            <Step key={label} active expanded>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.content}>
          <Paper className={classes.generator}>
            <TextField
              label="Paste PGN here"
              multiline
              minRows={20}
              maxRows={20}
              variant="outlined"
              fullWidth
              value={pgnImport}
              onChange={(e) => { this.setState({ pgnImport: e.target.value })}}
              error={!pgn}
              helperText={pgnImport && !pgn ? "Invalid PGN. Remeber that each list of moves must end with either '*' or a result, e.g. '1-0'" : false}
            />
            <Button
              startIcon={<ClearIcon />}
              onClick={this.clearPgn}
              className={classes.clearButton}
            >Clear PGN</Button>
            <div className={classes.textOptions}>
              <TextField
                label="Left header text"
                variant="outlined"
                value={leftHeader}
                helperText="Optional text on all pages"
                onChange={(e) => { this.changeTextOption('leftHeader', e.target.value) }}
                className={classes.textOption}
                margin="dense"
              />
              <TextField
                label="Right header text"
                variant="outlined"
                value={rightHeader}
                helperText="Optional text on all pages"
                onChange={(e) => { this.changeTextOption('rightHeader', e.target.value) }}
                className={classes.textOption}
                margin="dense"
              />
              <TextField
                label="Left footer text"
                variant="outlined"
                value={leftFooter}
                helperText="Optional text on all pages"
                onChange={(e) => { this.changeTextOption('leftFooter', e.target.value) }}
                className={classes.textOption}
                margin="dense"
              />
            </div>
            <div className={classes.textOptions}>
              <TextField
                label="Right footer text"
                variant="outlined"
                value={`created in ${settings.baseUrl}`}
                helperText="Optional text on all pages"
                onClick={() => { this.setPurchaseStep(1); emitEvent(eventTypes.premiumInputClick)}}
                className={classes.purchaseButton}
                margin="dense"
              />
            </div>
            <div className={classes.numberOptions}>
              <TextField
                label="Enumerate pages from"
                helperText="Leave 1 for default"
                InputProps={{
                  type: "number",
                }}
                variant="outlined"
                value={enumeratePagesFrom}
                onChange={(e) => { this.changeNumberOption('enumeratePagesFrom', e.target.value) }}
                className={classes.numberOption}
                margin="dense"
              />
              <TextField
                label="Enumerate diagrams from"
                helperText="Leave 1 for default"
                InputProps={{
                  type: "number",
                }}
                variant="outlined"
                value={enumerateDiagramsFrom}
                onChange={(e) => { this.changeNumberOption('enumerateDiagramsFrom', e.target.value) }}
                className={classes.numberOption}
                margin="dense"
              />
            </div>
            <FormGroup className={classes.boolOptions}>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeGameInfo}
                    onChange={() => this.changeBoolOption('includeGameInfo', !includeGameInfo)}
                    name="includeGameInfo"
                  />}
                label="Include game info for diagrams (players, event, date, if exists in PGN)"
                className={classes.boolOption}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={includeSolutionSpace}
                    onChange={() => this.changeBoolOption('includeSolutionSpace', !includeSolutionSpace)}
                    name="includeSolutionSpace"
                  />}
                label="Include space for solutions (dotted line below every diagram)"
                className={classes.boolOption}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={includeSolutionPages}
                    onChange={() => this.changeBoolOption('includeSolutionPages', !includeSolutionPages)}
                    name="includeSolutionPages"
                  />}
                label="Generate solution pages from PGN at the very end (if moves exist in PGN)"
                className={classes.boolOption}
              />
            </FormGroup>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                className={classes.generateButton}
                disabled={!pgn || generating}
                onClick={() => {
                  logButtonClick('generate-pdf');
                  this.createTexFile(pgn);
                }}
                startIcon={generating && <CircularProgress className={classes.generateProgress} size={20} />}
              >Generate PDF</Button>
            </div>
            <div className={classes.shares}>
              <TwitterShareButton
                url={settings.baseUrl}
                title="Convert chess PGN to a beautiful PDF:"
                via="ChessvisionAi"
                onClick={() => { logButtonClick('share-on-twitter') }}
                className={classes.twitterShare}
              >
                <TwitterIcon size={32} round={true} />
                <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Share on Twitter</Typography>
              </TwitterShareButton>
            </div>
          </Paper>
          <Paper className={classes.sidebar}>
            <Typography variant="h6">
              Do you also study chess ebooks?
            </Typography>
            <Typography className={classes.sidebarSubheader}>
              Check our Reader that makes chess ebooks interactive
            </Typography>
            <div className={classes.sidebarVideoContainer}>
              <div className="video-container video-container-large">
                <LiteYouTubeEmbed
                  id="5wIv3OP7VWM"
                  params="autoplay=1&autohide=1&showinfo=0&rel=0"
                  poster="maxresdefault"
                  webp
                />
                </div>
            </div>
            <Button
              variant="contained"
              color="secondary"
              target="_blank"
              href={settings.readerUrl}
              onClick={() => { logButtonClick('sidebar:ebook-reader') }}
              className={classes.sidebarButton}
            >Open Reader</Button>
          </Paper>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(Home);
